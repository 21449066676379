import React, { useEffect, useState, useContext } from "react"
import { useTranslation } from "react-i18next"
import "react-datepicker/dist/react-datepicker.css"
import {
  Asset,
  Status,
  refundStatus,
  TransactionType,
  // DebitCredit,
  RefundMode
} from "src/constants/FilterTransactionConstants"
import { UserContext } from "src/context/User"
import dayjs from "dayjs"

import { FilterDropdown } from "./FilterDropdown"
import { FilterDateDropdown } from "./FilterDateDropdown"
import { FilterSearch } from "./FilterSearch"

interface Props {
  submitFilter: (filters: { [key: string]: string }) => void
  filters: { [key: string]: string }
}

export const useRefundFilterSelectField = ({
  submitFilter,
  filters
}: Props) => {
  const { t } = useTranslation()
  const { user } = useContext(UserContext)

  const [dateFrom, setDateFrom] = useState<Date | null>(
    filters.dateFrom ? new Date(filters.dateFrom) : null
  )
  const [dateTo, setDateTo] = useState<Date | null>(
    filters.dateTo ? new Date(filters.dateTo) : null
  )
  const [filterAsset, setFilterAsset] = useState<string | undefined>(
    filters.asset
  )
  /* const [filterStatus, setFilterStatus] = useState<string | undefined>(
    filters.status
  )*/
  const [filterRefundStatus, setFilterRefundStatus] = useState<
    string | undefined
  >(filters.refundStatus)
  const [filterTransactionType, setFilterTransactionType] = useState<
    string | undefined
  >(filters.type)
  // const [filterDebitCredit, setFilterDebitCredit] = useState<
  //   string | undefined
  // >(filters.direction)
  const [searchTxId, setSearchTxId] = useState<string | undefined>(
    filters.tx_id
  )
  const [filterRefundMode, setFilterRefundMode] = useState<string | undefined>(
    filters.refundMode
  )

  useEffect(() => {
    submitFilter({
      ...(filterAsset && { asset: filterAsset }),
      // ...(filterStatus && { status: filterStatus }),
      ...(filterRefundStatus && { refundStatus: filterRefundStatus }),
      ...(dateFrom && {
        dateFrom: `${dayjs(dateFrom.toString()).format(
          "YYYY-MM-DD"
        )}T00:00:00.000Z`
      }),
      ...(dateTo && {
        dateTo: `${dayjs(dateTo.toString()).format("YYYY-MM-DD")}T23:59:59.999Z`
      }),
      ...(filterTransactionType && { type: filterTransactionType }),
      // ...(filterDebitCredit && { direction: filterDebitCredit }),
      ...(searchTxId && { uuid: searchTxId }),
      ...(filterRefundMode && { refundMode: filterRefundMode })
    })
  }, [
    filterAsset,
    //filterStatus,
    filterRefundStatus,
    dateFrom,
    dateTo,
    filterTransactionType,
    // filterDebitCredit,
    searchTxId,
    filterRefundMode
  ])

  const setDates = (dateFrom: Date | null, dateTo: Date | null) => {
    setDateFrom(dateFrom)
    setDateTo(dateTo)
  }

  const searchText = (searchTerm: string) => {
    if (searchTerm === "") {
      setSearchTxId(undefined)
      return
    }

    if (searchTerm !== undefined && searchTerm.includes("-")) {
      setSearchTxId(searchTerm)
      return
    }
  }

  let allAssets = [...Asset]
  if (user && user.attributes.email == "ddf.pilot@wadzpay.com") {
    const arr = []
    arr[0] = Asset[1]
    arr[1] = Asset[2]
    allAssets = arr
  } else {
    allAssets = [...Asset]
  }

  const FilterSelectField: React.FC = () => {
    return (
      <div id="filter-data">
        <div className="d-flex flex-row">
          <FilterDropdown
            title={t("Token")}
            value={filterAsset}
            items={allAssets}
            setFilter={(value?: string) => setFilterAsset(value)}
          />
          {/* <FilterDropdown
            title={t("Status")}
            value={filterStatus}
            items={Status}
            setFilter={(value?: string) => setFilterStatus(value)}
          />*/}
          <FilterDropdown
            title={t("Refund Status")}
            value={filterRefundStatus}
            items={refundStatus}
            setFilter={(value?: string) => setFilterRefundStatus(value)}
          />
          {/* <FilterDropdown
            title={t("Transaction Type")}
            value={filterTransactionType}
            items={TransactionType}
            setFilter={(value?: string) => setFilterTransactionType(value)}
          /> */}
          {/* <FilterDropdown
            title={t("Transaction Type")}
            value={filterDebitCredit}
            items={DebitCredit}
            setFilter={(value?: string) => setFilterDebitCredit(value)}
          /> */}

          <FilterDropdown
            title={t("Refund Mode")}
            value={filterRefundMode}
            items={RefundMode}
            setFilter={(value?: string) => setFilterRefundMode(value)}
          />

          <FilterDateDropdown
            title={t("Date Range")}
            dateFrom={dateFrom}
            dateTo={dateTo}
            setFilter={(dateFrom: Date | null, dateTo: Date | null) =>
              setDates(dateFrom, dateTo)
            }
          />

          <FilterSearch
            title={"Search"}
            searchDefaultTxt={t("Transaction ID")}
            value={searchTxId}
            setFilterSearch={(value?: string) => searchText(value || "")}
          />
        </div>
      </div>
    )
  }
  return FilterSelectField
}
