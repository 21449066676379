import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { Button, Dropdown } from "react-bootstrap"
import Modal from "react-bootstrap/Modal"
import Form from "react-bootstrap/Form"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
import Container from "react-bootstrap/Container"
import { useNavigate } from "react-router-dom"
import { RouteType } from "src/constants/routeTypes"
import { useSubmitRefundformWithAuth } from "src/api/user"
import BlockUI from "src/helpers/BlockUI"
import { useGetExchangeRate } from "src/api/onRamp"
import {
  Asset,
  CryptoFullName,
  FiatAsset,
  TokenToAmount
} from "src/constants/types"

import { ENV } from "../../../env"

function CustomerVeriRefundPopupModal(props: any) {
  const { rowtrx, refundableFiatAmount } = props

  const { t } = useTranslation()
  const navigate = useNavigate()
  const [showCustomerPop, setShowCustomerPop] = useState(true)
  const [loaderShow, setLoaderShow] = useState(false)
  const [termsChecked, setTermsChecked] = useState(false)
  const [formErrorMessage, setFormErrorMessage] = useState("")
  const [customerVerificationSubmitBtn, setCustomerVerificationSubmitBtn] =
    useState(false)
  const [initiateData, setInitiateData] = useState({
    refundDigitalType: rowtrx.asset,
    refundFiatType: rowtrx.fiatAsset,
    refundAmountFiat: refundableFiatAmount,
    refundUserName: "",
    refundUserMobile: "",
    refundUserMobileCountryCode: "",
    refundUserEmail: "",
    refundWalletAddress: "",
    refundWalletAddressConfirm: "",
    refundAmountDigital: 0,
    reasonForRefund: ""
  })
  const { data: exchangeRatesData, isFetching: isFetchingExchangeRates } =
    useGetExchangeRate(rowtrx.fiatAsset)

  const digitalAsset = ENV.DEV || ENV.TESTING ? "BTC" : "USDT"

  const {
    mutate: submitRefundform,
    error,
    isSuccess
  } = useSubmitRefundformWithAuth()

  useEffect(() => {
    if (isSuccess) {
      setLoaderShow(true)
      setTimeout(() => {
        window.location.reload()
      }, 2000)
    }
  }, [isSuccess])

  useEffect(() => {
    if (error?.message) {
      setLoaderShow(false)
    }
  }, [error])

  useEffect(() => {
    if (rowtrx) {
      // console.log(rowtrx)
    }
  }, [rowtrx])

  useEffect(() => {
    if (
      initiateData &&
      initiateData.refundUserName &&
      initiateData.reasonForRefund &&
      initiateData.refundWalletAddress &&
      initiateData.refundWalletAddressConfirm &&
      termsChecked
    ) {
      // console.log(rowtrx)
      setCustomerVerificationSubmitBtn(true)
    } else {
      setCustomerVerificationSubmitBtn(false)
    }
  }, [
    initiateData,
    initiateData.refundUserName,
    initiateData.reasonForRefund,
    initiateData.refundWalletAddress,
    initiateData.refundWalletAddressConfirm,
    termsChecked
  ])

  const handleClose = () => setShowCustomerPop(false)
  const handleShow = () => setShowCustomerPop(true)

  function storeData(e: any, input: any) {
    switch (input) {
      case "name":
        setInitiateData({
          ...initiateData,
          refundUserName: e.target.value
        })

        break
      case "mobileCode":
        setInitiateData({
          ...initiateData,
          refundUserMobileCountryCode: e.target.value
        })
        break
      case "mobileNumber":
        setInitiateData({ ...initiateData, refundUserMobile: e.target.value })
        break
      case "email":
        setInitiateData({ ...initiateData, refundUserEmail: e.target.value })
        break
      case "refundreason":
        setInitiateData({ ...initiateData, reasonForRefund: e.target.value })
        break
      case "walletaddress":
        setInitiateData({
          ...initiateData,
          refundWalletAddress: e.target.value
        })
        break
      case "walletaddressconfirm":
        setInitiateData({
          ...initiateData,
          refundWalletAddressConfirm: e.target.value
        })
        break
      default:
        break
    }
  }

  const fiatRecievedAmount = rowtrx.totalFiatRecieved
    ? rowtrx.totalFiatRecieved.toFixed(8)
    : 0

  function renderRefundAmount() {
    if (exchangeRatesData && initiateData) {
      const value =
        exchangeRatesData[digitalAsset] * initiateData.refundAmountFiat
      const finalVal = value.toFixed(8)
      return parseFloat(finalVal)
    }
  }

  function submitData() {
    setFormErrorMessage("")
    // const obj = {
    //   refundDigitalType: rowtrx.asset,
    //   refundFiatType: rowtrx.fiatAsset,
    //   refundAmountFiat: initiateData.refundAmountFiat,
    //   transactionId: rowtrx.uuid,
    //   refundUserName: initiateData.refundUserName,
    //   refundUserMobile:
    //     initiateData.refundUserMobileCountryCode +
    //       initiateData.refundUserMobile ===
    //     ""
    //       ? null
    //       : initiateData.refundUserMobileCountryCode +
    //         initiateData.refundUserMobile,
    //   refundUserEmail:
    //     initiateData.refundUserEmail === ""
    //       ? null
    //       : initiateData.refundUserEmail,
    //   refundWalletAddress: initiateData.refundWalletAddressConfirm,
    //   refundAmountDigital: renderRefundAmount() || 0,
    //   reasonForRefund: initiateData.reasonForRefund,
    //   refundMode: "WALLET"
    // }
    // console.log(obj)
    const pattern = /^[A-Za-z ]+$/
    if (!pattern.test(initiateData.refundUserName)) {
      setFormErrorMessage("Enter Valid Customer Name")
      return
    }
    // if (initiateData.refundUserMobileCountryCode == "") {
    //   setFormErrorMessage("Select Mobile Number Country Code")
    //   return
    // }
    // if (initiateData.refundUserMobile == "") {
    //   setFormErrorMessage("Fill Mobile Number")
    //   return
    // }
    // if (initiateData.refundUserEmail == "") {
    //   setFormErrorMessage("Fill Email Address")
    //   return
    // }
    if (initiateData.reasonForRefund == "") {
      setFormErrorMessage("Fill Reason for Refund")
      return
    }
    if (initiateData.refundWalletAddress == "") {
      setFormErrorMessage("Fill Wallet Address")
      return
    }
    if (initiateData.refundWalletAddressConfirm == "") {
      setFormErrorMessage("Fill Confirm Wallet Address")
      return
    }
    if (
      initiateData.refundWalletAddressConfirm !=
      initiateData.refundWalletAddress
    ) {
      setFormErrorMessage(
        "Wallet Address And Confirm Wallet Address Not Matching"
      )
      return
    }
    if (
      !(
        initiateData.refundWalletAddressConfirm &&
        initiateData.refundWalletAddress.length >= 32 &&
        initiateData.refundWalletAddress.length <= 48
      )
    ) {
      setFormErrorMessage("Wallet Address not Valid")
      return
    }

    if (!termsChecked) {
      setFormErrorMessage("Read Terms and Conditions and Accept")
      return
    }
    if (
      initiateData.refundUserMobile !== "" &&
      initiateData.refundUserMobileCountryCode === ""
    ) {
      setFormErrorMessage("Select Mobile Code")
      setTimeout(() => {
        setFormErrorMessage("")
      }, 2000)
      return
    }
    setLoaderShow(true)
    submitRefundform({
      refundDigitalType: rowtrx.asset,
      refundFiatType: rowtrx.fiatAsset,
      refundAmountFiat: initiateData.refundAmountFiat,
      transactionId: rowtrx.uuid,
      refundUserName: initiateData.refundUserName,
      refundUserMobile:
        initiateData.refundUserMobileCountryCode +
          initiateData.refundUserMobile ===
        ""
          ? null
          : initiateData.refundUserMobileCountryCode +
            initiateData.refundUserMobile,
      refundUserEmail:
        initiateData.refundUserEmail === ""
          ? null
          : initiateData.refundUserEmail,
      refundWalletAddress: initiateData.refundWalletAddressConfirm,
      refundAmountDigital: renderRefundAmount() || 0,
      reasonForRefund: initiateData.reasonForRefund,
      refundMode: "WALLET"
    })
  }

  return (
    <>
      <Modal
        show={showCustomerPop}
        onHide={handleClose}
        size="lg"
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <BlockUI blocking={loaderShow} title="submitting" />
          <Modal.Title></Modal.Title>
          {formErrorMessage && (
            <div
              className="alert alert-danger"
              role="alert"
              style={{ textAlign: "center" }}
            >
              {formErrorMessage}
            </div>
          )}
        </Modal.Header>
        <Modal.Body
          style={{ height: "550px", overflowY: "scroll" }}
          className="show-grid"
        >
          {error && (
            <div className="alert alert-danger" role="alert">
              {error.message}
            </div>
          )}
          <Container>
            <Row>
              <Col
                className="col-md-10 mb-4"
                md={{ offset: 1 }}
                style={{ marginBottom: "10px" }}
              >
                <h2 className="refund-header">
                  Customers Verification form for Refund
                </h2>
                <Form>
                  <Form.Group className="mb-3">
                    <Form.Label>Transaction reference</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Transaction reference"
                      value={rowtrx.uuid}
                      disabled
                      inputMode="text"
                    />
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label>Customer Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Customer Name"
                      onChange={(e) => storeData(e, "name")}
                      value={initiateData.refundUserName}
                    />
                    {/* {initiateData.refundUserName &&
                      !initiateData.refundUserName.match(/^[A-Za-z]+$/) && (
                        <p className="text-danger">Enter Valid Name!</p>
                      )} */}
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label>Mobile number</Form.Label>
                    <div className="mobileNumberInputs">
                      <Form.Select onChange={(e) => storeData(e, "mobileCode")}>
                        <option value="">Code</option>
                        <option value="+91">+91</option>
                        <option value="+971">+971</option>
                        <option value="+61">+61</option>
                      </Form.Select>
                      <Form.Control
                        type="text"
                        placeholder="Mobile number"
                        onChange={(e) => storeData(e, "mobileNumber")}
                      />
                    </div>
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label>Email Address</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Email Address"
                      onChange={(e) => storeData(e, "email")}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label>Digital currency amount</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Digital currency amount"
                      value={rowtrx.totalDigitalCurrencyReceived}
                      disabled
                    />
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label>Digital currency name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Digital currency name"
                      value={rowtrx.asset}
                      disabled
                    />
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label>Refund Amount in {rowtrx.fiatAsset}</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder={`Refund Amount in ${rowtrx.fiatAsset}`}
                      value={initiateData.refundAmountFiat}
                      disabled
                    />
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label>Refund Amount in {digitalAsset}</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder={`Refund Amount in ${digitalAsset}`}
                      value={renderRefundAmount()?.toFixed(8)}
                      disabled
                    />
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label>Reason for Refund</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Reason for Refund"
                      value={initiateData.reasonForRefund}
                      onChange={(e) => storeData(e, "refundreason")}
                    />
                    <Form.Text className="text-muted">
                      to be filled by customer
                    </Form.Text>
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label>wallet address</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder={`wallet address`}
                      value={initiateData.refundWalletAddress}
                      onChange={(e) => storeData(e, "walletaddress")}
                    />
                    <Form.Text className="text-muted">
                      to be filled by customer
                    </Form.Text>
                    {/* {initiateData.refundWalletAddress === "" &&
                      (initiateData.refundWalletAddress.length < 24 ||
                        initiateData.refundWalletAddress.length > 32) && (
                        <p className="text-danger">Enter Valid Wallet Address!</p>
                      )} */}
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label>Reconfirm wallet address</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder={`Reconfirm wallet address`}
                      value={initiateData.refundWalletAddressConfirm}
                      onChange={(e) => storeData(e, "walletaddressconfirm")}
                    />
                    <Form.Text className="text-muted">
                      to be filled by customer
                    </Form.Text>
                  </Form.Group>
                  <h4>
                    Declaration signed by the customer to the effect that
                    (Customer to accept and sign)
                  </h4>
                  <ul className="list-details">
                    <li>
                      Refund will be in {digitalAsset}, into a {digitalAsset}
                      wallet
                    </li>
                    <li>Gas fee will be borne by the Customer</li>
                    <li>
                      It may take up to 1 weeks for the refund to take place
                    </li>
                    <li>
                      Refund amount could be less than the original transaction
                      amount (equivalent)due to
                      <br />
                      gas fee, conversion from the original crypto currency to{" "}
                      {digitalAsset} and the conversion rate at <br />
                      the time of execution of the refunded transaction{" "}
                    </li>
                  </ul>
                  <Form.Group
                    className="mb-3 mt-4"
                    controlId="formBasicCheckbox"
                  >
                    <div style={{ display: "flex" }}>
                      <Form.Check
                        type="checkbox"
                        label={`I accept the`}
                        checked={termsChecked}
                        onChange={() => setTermsChecked(!termsChecked)}
                      />
                      <a href="#" style={{ marginLeft: "5px" }}>
                        Terms and Conditions
                      </a>
                    </div>
                  </Form.Group>
                </Form>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer style={{ justifyContent: "center" }}>
          {/* <div>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={handleClose}
              style={{ marginRight: "10px" }}
            >
              Close
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => submitData(id)}
            >
              Submit
            </button>
          </div> */}
          <div style={{ textAlign: "center" }}>
            <button
              type="submit"
              // className={
              //   (initiateData.refundUserName &&
              //     !initiateData.refundUserName.match(/^[A-Za-z]+$/)) ||
              //   (initiateData.refundWalletAddress === "" &&
              //     (initiateData.refundWalletAddress.length < 32 ||
              //       initiateData.refundWalletAddress.length > 48))
              //     ? "wdz-btn-grey wdz-btn-md"
              //     : "wdz-btn-primary wdz-btn-md"
              // }
              className={
                customerVerificationSubmitBtn
                  ? "wdz-btn-primary wdz-btn-md"
                  : "wdz-btn-grey wdz-btn-md"
              }
              style={{ marginRight: "20px" }}
              onClick={() => submitData()}
              // disabled={
              //   (initiateData.refundUserName &&
              //     !initiateData.refundUserName.match(/^[A-Za-z]+$/)) ||
              //   (initiateData.refundWalletAddress === "" &&
              //     (initiateData.refundWalletAddress.length < 24 ||
              //       initiateData.refundWalletAddress.length > 32))
              //     ? true
              //     : false
              // }
              disabled={!customerVerificationSubmitBtn}
            >
              Submit
            </button>
            <button
              className="wdz-btn-grey wdz-btn-md ml-4"
              onClick={() => props.showhide()}
            >
              Cancel
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default CustomerVeriRefundPopupModal
